/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import theme from '@lib/theme';

interface Props {
  /** text to display in the button */
  children: React.ReactChild;
  /** button variant */
  variant?: 'primary' | 'secondary' | 'outline' | 'pill';
  /** button type */
  type?: 'button' | 'submit' | 'reset';
  css?: any;
  /** onChange handler */
  onClick?: () => void;
  /** onFocus handler */
  onFocus?: () => void;
  /** onBlur handler */
  onBlur?: () => void;
  /** is disabled */
  disabled?: boolean;
}

export const styledButton = css`
  background-color: ${theme.colors.purple400};
  border-radius: 0.75rem;
  border: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 1;
  padding: 0.925rem 1.75rem;
  text-decoration: none;
  text-transform: uppercase;

  &:disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus,
  &:active {
    background-color: ${theme.colors.purple300};
    outline: 0;
  }

  &[data-variant='primary'] {
    background-color: ${theme.colors.pink500};
  }

  &[data-variant='outline'] {
    background-color: transparent;
    border-radius: 12px;
    border: 2px solid ${theme.colors.purple400};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
  }

  &[data-variant='pill'] {
    border-radius: 50rem;
    background-color: transparent;
    color: ${theme.colors.purple100};
    border: 1px solid ${theme.colors.purple100};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    padding: 0.4rem 0.625rem 0.325rem;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1;
    margin-left: 0;
    text-transform: none;
  }
`;

const Button: React.FC<Props> = ({
  children,
  disabled,
  onBlur,
  onClick,
  onFocus,
  type,
  variant,
  css: styles,
  ...props
}: Props) => (
  <button
    css={css`
      ${styledButton};
      ${styles ? styles : ''}
    `}
    data-variant={variant || 'default'}
    disabled={disabled}
    onBlur={onBlur}
    onClick={onClick}
    onFocus={onFocus}
    type={type || 'button'}
    {...props}
  >
    {children}
  </button>
);

export default Button;
