/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from '@reach/router';

import Triangle from '@assets/icons/Triangle';
import { styledButton } from '@components/Button';
import Container from '@components/Container';
import theme from '@lib/theme';

const Hero: React.FC = () => {
  return (
    <Container>
      <header
        css={css`
          margin: 0 auto;
          max-width: 50rem;
          // padding-bottom: 6rem;
          padding-top: 3rem;
          text-align: center;
          width: 100%;
          position: relative;
          z-index: 20;

          @media (min-width: ${theme.breakpoints.lg}) {
            padding-bottom: 8rem;
          }
        `}
      >
        <h1
          css={css`
            margin-bottom: 0.625rem;
            font-size: 2.25rem;
            line-height: 1.2;

            @media (min-width: ${theme.breakpoints.lg}) {
              font-size: 3.625rem;
            }
          `}
        >
          Hoss has been acquired by Niantic Labs!
        </h1>
        <a
          href="https://nianticlabs.com/blog/hoss/"
          data-variant="primary"
          css={css`
            ${styledButton};
            display: inline-block;
            font-size: 1.125rem;
            padding-bottom: 1.125rem;
            padding-left: 3.5rem;
            padding-right: 3.5rem;
            padding-top: 1.25rem;
            margin-top: 4rem;
            width: auto;

            @media (min-width: ${theme.breakpoints.md}) {
              font-size: 1rem;
              padding-bottom: 1rem;
              padding-left: 2.5rem;
              padding-right: 2.5rem;
              padding-top: 1.125rem;
            }

            &:hover {
              color: #fff;
            }
          `}
        >
          <span>Read the announcement</span>
        </a>
      </header>
    </Container>
  );
};

export default Hero;
