/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { PageProps } from 'gatsby';

// import WaveOne from '@assets/blog/list/WaveOne';
import WaveThreeLg from '@assets/home/waves/large/WaveThreeLg';
import HossHero from '@assets/HossHero';
import Container from '@components/Container';
import Hero from '@components/Hero';
import MetaData from '@components/MetaData';
import theme from '@lib/theme';

const IndexPage: React.FC<
  PageProps & {
    data: { allMarkdownRemark: { edges: Record<string, unknown>[] } };
  }
> = ({ data }) => {
  return (
    <div>
      <MetaData
        title="The Developer Experience Platform"
        description="The Hoss gives API-driven teams the tools they need to commercialize API offerings, monitor critical integrations, understand their customers and product usage, and grow and support their customer base."
      />
      {/* <WaveOne /> */}
      <Hero />
      {/* <HossHero /> */}
      {/* <img
        src="/images/home/mobile-hero.png"
        css={css`
          display: block;
          height: auto;
          width: 100%;

          @media (min-width: ${theme.breakpoints.md}) {
            display: none;
          }
        `}
      /> */}


      {/* <WaveThreeLg /> */}
    </div>
  );
};

export default IndexPage;
