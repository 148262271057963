import { formatToTimeZone } from 'date-fns-timezone';
/**
 * Get a formatted version of the current page title
 *
 * @param {string} pageTitle
 */
export function getPageTitle(pageTitle: string): string {
  return `Hoss | ${pageTitle}`;
}

/**
 * Titlecase a string
 *
 * @param {string} phrase
 */
export function toTitlecase(phrase: string): string {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 * Sign up user
 *
 * @param {string} email Email address
 * @param {string} source Form location
 */
export function doEmailSignup(
  email: string,
  source: string,
): Promise<Response> {
  const analytics = (window as any).analytics;
  let anonymousId: string|undefined = undefined
  if (analytics) {
    anonymousId = analytics.user().anonymousId()
  }
  return fetch('https://leads.hoss.com/leads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // this is a pretty bad way to store secrets, but in absence of alternatives
      Authorization: 'Bearer 51a674ac-8046-442e-a32a-522d3b6e22a0',
    },

    body: JSON.stringify({ email, source, anonymousId, referrer: document.referrer }),
  });
}

/**
 * Format a timestamp for the blog
 *
 * @param {string} time
 */
export function formatTime(time: string): string {
  const hour = formatToTimeZone(new Date(time), 'h:mm aa', {
    timeZone: 'America/New_York',
  });
  const date = formatToTimeZone(new Date(time), 'MMMM D, YYYY', {
    timeZone: 'America/New_York',
  });

  return `${hour} &middot; ${date}`;
}

/**
* Group object array by property
 * Example, groupBy(array, ( x: Props ) => x.id );
 * @param array
 * @param property
 */
export const groupBy = <T>(array: Array<T>, property: (x: T) => string): { [key: string]: Array<T> } =>
  array.reduce((memo: { [key: string]: Array<T> }, x: T) => {
    if (!memo[property(x)]) {
      memo[property(x)] = [];
    }
    memo[property(x)].push(x);
    return memo;
  }, {});